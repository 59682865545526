import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { unoptimzeGatsbyImageSource } from "../helpers/common"

export const DotMapImage = () => {
  const { img: { childImageSharp } } = useStaticQuery(
    graphql`
      query {
        img: file(relativePath: { eq: "dot-map-bg.jpg" }) {
          childImageSharp {
            original {
              src
              width
            }
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <Img fluid={unoptimzeGatsbyImageSource(childImageSharp)} />
  )
}