import styled from "styled-components"

import { col, containerSideOffset, HexagonTitleMain, SectionTitleMain } from "./common.styles"

export const LeadershipMain = styled.div`
padding: 310px 0 230px;

${props => props.theme.max('lt', () => (`
  padding: 220px 0 230px;
`))}

${props => props.theme.max('md', (props) => (`
  padding: 180px 0 80px;
`), props)}

${props => props.theme.max('sm', (props) => (`
  padding: 0 0 50px;

  &:before {
    content: '';
    height: 84px;
    display: block;
    background-color: ${props.theme.colors.primary};
  }
`), props)}

.main-col {
  ${containerSideOffset()}
  ${col(6, 'c')}
  padding-top: 110px;

  ${props => props.theme.max('lt', () => (`
    padding-top: 70px;
  `))}

  ${props => props.theme.max('mac', (props) => (`
    ${col(36)}
  `), props)}

  ${props => props.theme.max('md', () => (`
    padding-top: 10px;
    ${col(50)}
  `))}

  ${props => props.theme.max('sm', (props) => (`
    padding-top: 90px;
    padding-bottom: 50px;
    ${col(100)}
  `), props)}
}

${HexagonTitleMain} {
  margin-bottom: 76px;
}

${SectionTitleMain} {
  margin-bottom: .36em;

  ${props => props.theme.max('sm', () => (`
    margin-bottom: 0.76em;
  `))}
}

.side-col {
  ${containerSideOffset('right', 3)}
  ${col(8, 'c')}
  margin-left: auto;

  ${props => props.theme.max('md', () => (`
    ${col(45)}
  `))}
}

.img-text-col {
  ${containerSideOffset()}
  ${col(6.2, 'c')}
  padding-top: 22px;

  ${props => props.theme.max('md', () => (`
    ${col(45)}
  `))}

  ${props => props.theme.max('sm', () => (`
    padding-top: 56px;
    order: 2;
    ${col(100)}
  `))}
}

.img-col {
  ${col(11, 'c')}
  margin-left: auto;

  ${props => props.theme.max('md', () => (`
    order: 1;
  `))}
  
  ${props => props.theme.max('sm', () => (`
    ${col(100)}
    ${containerSideOffset()}
  `))}
}

.quote-col {
  ${col(100)}
  padding: 137px 0 182px;

  ${props => props.theme.max('mac', () => (`
    padding-top: 80px;
  `))}

  ${props => props.theme.max('md', (props) => (`
    padding: 0 0 10px;
  `), props)}
}

.to-animate {
  .quote-content {
    opacity: 0;
    transform: translate(-100px, 0);

    ${props => props.theme.max('sm', () => (`
      transform: translate(0, 100px);
    `))}
  }

  .quote-sig-wrap {
    opacity: 0;
  }
}

.to-animate:not(.is-in-vp) {
  .quote-sig-wrap,
  .quote-content {
    transition-delay: 0ms !important;
  }
}

.to-animate.is-in-vp {
  .quote-content {
    transition: ${800}ms ease 800ms;
    transform: translate(0);
    opacity: 1;

    ${props => props.theme.max('sm', () => (`
      transform: translate(0);
    `))}
  }
  
  .quote-sig-wrap {
    transition: ${800}ms ease 800ms;
    opacity: 1;
  }
}

.img-col-wrap {
  align-items: center;
  padding-top: 60px;
}

`