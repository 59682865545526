import styled from "styled-components"

import { HexagonTitleMain, SectionTitleMain } from "./common.styles"

export const BannerMain = styled.div`
position: relative;
padding: 200px 0;
display: flex;
align-items: center;

${props => props.theme.min('mac', (props) => (`
  min-height: 800px;
`), props)}


${props => props.theme.max('sm', (props) => (`
  padding: 100px 0 190px;
  background-color: ${props.theme.colors.primary200};
  min-height: 667px;
`), props)}

${HexagonTitleMain} {
  margin-bottom: 25px;

  ${props => props.theme.max('sm', (props) => (`
    margin-bottom: 73px;
  `), props)}
}

.gatsby-image-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.banner-content {
  position: relative;

  a {
    span {
      color: #fff;
    }
  }
}

${SectionTitleMain} {
  color: ${props => props.theme.colors.white};
  margin-bottom: .3em;

  ${props => props.theme.max('sm', (props) => (`
    margin-bottom: 1em;
  `), props)}
}

.btn {
  color: ${props => props.theme.colors.white};
}

&.large {
  padding: 251px 0;

  ${props => props.theme.max('sm', () => (`
    padding: 100px 0 190px;

    ${SectionTitleMain} {
      max-width: 250px;
    }
  `))}
}


&.contact-page {
  overflow: hidden;
  padding: 0 !important;
  background-color: ${props => props.theme.colors.primary100};

  .banner-inner {
    margin-top: 141px;
    padding: 281px 0 381px;
    position: relative;
    
    ${props => props.theme.max('mac', () => (`
      padding: 200px 0;
    `))}
    
    ${props => props.theme.max('lt', () => (`
      padding: 140px 0;
    `))}

    ${props => props.theme.max('sm', () => (`
      padding: 140px 0 261px;
      margin-top: 85px;
    `))}
  }

  ${HexagonTitleMain} {
  margin-bottom: 75px;
  }
}

.mobile {
  ${props => props.theme.min('sm', () => (`
    display: none;
  `))}
}

.desktop {
  ${props => props.theme.max('sm', () => (`
    display: none;
  `))}
}

.contact-bg {
  position: absolute;
  width: 56%;
  right: 5.4%;
  top: 7%;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;    
  }

  [class^="brev-dot-"] {
    animation-name: contactDotAnimation;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    animation-delay: .4s;
    animation-fill-mode: both;
    transform-box: fill-box;
    transform-origin: center center;
    fill: #d1c8ba;
  }

  .brev-dot-1 {
    animation-duration: 3.1s;
    animation-delay: 0.1s;
  }
  .brev-dot-2 {
    animation-duration: 3.2s;
    animation-delay: 0.2s;
  }
  .brev-dot-3 {
    animation-duration: 3.3s;
    animation-delay: 0.3s;
  }
  .brev-dot-4 {
    animation-duration: 3.4s;
    animation-delay: 0.4s;
  }
  .brev-dot-5 {
    animation-duration: 3.5s;
    animation-delay: 0.5s;
  }
  .brev-dot-6 {
    animation-duration: 3.6s;
    animation-delay: 0.6s;
  }

  @keyframes contactDotAnimation {
    0% {
      transform: scale(0);
    }

    80% {
      transform: scale(1);
      opacity: 1;
    }

    100% {
      transform: scale(1.5);
      opacity: 0;
    }
  }
}
`
