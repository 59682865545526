import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { AtGlanceSection } from "../components/at-glance-section"
import { LeadershipSection } from "../components/leadership-section"
import { GlobeSection } from "../components/globe-section"
import { MethodologySection } from "../components/methodology-section"
import { NumSection } from "../components/num-section"
import { BannerSection } from "../components/banner-section"
import { processHexagonData } from "../helpers/common"
import useModalProps from "../helpers/use-modal-props"
/* import AboutUsModal from "../components/about-us-modal" */

const AboutUsTemplate = ({
  data: {
    wpgraphql: {
      page: {
        title,
        aboutusacf
      }
    },
  }
}) => {
  const hexagonData = processHexagonData([
    aboutusacf.leadershipSection.name,
    aboutusacf.atAGlanceSectionName,
    aboutusacf.methodologySection.name,
    aboutusacf.globeSection.name,
    aboutusacf.banner.name,
  ]);

  const { modalProps } = useModalProps({ initialOpen: false, acceptType: '' });

  return (
    <Layout>
      <SEO title={title} />

      {/* <AboutUsModal modalProps={modalProps} /> */}

      <LeadershipSection
        hexagonData={hexagonData[0]}
        title={aboutusacf.leadershipSection.title}
        firstText={aboutusacf.leadershipSection.text}
        quoteText={aboutusacf.leadershipSection.quoteText}
        quoteName={aboutusacf.leadershipSection.fullName}
        quoteLabel={aboutusacf.leadershipSection.companyPosition}
        imageText={aboutusacf.leadershipSection.imageText}
        image1={aboutusacf.leadershipSection.image}
        image1Mobile={aboutusacf.leadershipSection.imageMobile}
        quoteImage={aboutusacf.leadershipSection.ceoPhoto}
        image2={aboutusacf.leadershipSection.image2}
      />

      <AtGlanceSection
        hexagonData={hexagonData[1]}
        title={aboutusacf.atAGlanceTitle}
        items={aboutusacf.atAGlanceItems}
        cutouts={"cutout-bottom cutout-secondary300"}
      />

      <MethodologySection
        leftTextColumn={aboutusacf.methodologySection.leftTextColumn}
        rightTextColumn={aboutusacf.methodologySection.rightTextColumn}
        title={aboutusacf.methodologySection.title}
        hexagonData={hexagonData[2]}
        list={aboutusacf.methodologySection.list}
        image1={aboutusacf.methodologySection.image}
      />

      <NumSection
        number={1}
        title={aboutusacf.macroThinkingTitle}
        items={aboutusacf.macroThinkingContent}
        image2={{ ...aboutusacf.macroThinkingImage, size: 'large' }}
      />

      <NumSection
        number={2}
        title={aboutusacf.tradeStructuringTitle}
        items={aboutusacf.tradeStructuringContent}
        image1={{ ...aboutusacf.tradeStructuringImage, size: 'large' }}
        image2={{ ...aboutusacf.tradeStructuringImage2, size: 'small' }}
        footnotes={aboutusacf?.tradeStructuringFootnotes || []}
        alignFootNotesBelow = "true"
        modalProps={modalProps}
      />

      <NumSection
        number={3}
        title={aboutusacf.riskManagementTitle}
        items={aboutusacf.riskManagementContent}
        image1={{ ...aboutusacf.riskManagementImage, size: 'small' }}
        image2={{ ...aboutusacf.riskManagementImage2, size: 'large' }}
      />

      <GlobeSection
        hexagonData={hexagonData[3]}
        title={aboutusacf.globeSection.title}
        text1={aboutusacf.globeSection.leftTextColumn}
        text2={aboutusacf.globeSection.rightTextColumn}
        linkData={aboutusacf.globeSection.acflinkAcfLink}
        linkUrl={'/'}
        linkText={"Login for Details"}
        linkClass={"about-pg-globe-btn"}
      />

      <BannerSection
        title={aboutusacf.banner.title}
        linkData={aboutusacf.banner.acfLink}
        background={aboutusacf.banner.image}
        backgroundMobile={aboutusacf.banner.imageMobile}
        hexagonData={{}}
      />

    </Layout>
  )
}

export default AboutUsTemplate

export const homeTemplateQuery = graphql`
  query AboutUsPageQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {
        title
        aboutusacf {
          atAGlanceSectionName
          atAGlanceTitle
          atAGlanceItems {
            itemIcon
            text
            title
          }

          macroThinkingTitle
          macroThinkingImage {
            mediaItemUrl
            mediaItemId
            modified
            localImageFile {
              childImageSharp {
                original {
                  src
                  width
                }
                fluid(maxWidth: 880) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          macroThinkingContent {
            text
            title
          }
          tradeStructuringTitle
          tradeStructuringImage {
            mediaItemUrl
            mediaItemId
            modified
            localImageFile {
              childImageSharp {
                original {
                  src
                  width
                }
                fluid(maxWidth: 880) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          tradeStructuringImage2 {
            mediaItemUrl
            mediaItemId
            modified
            localImageFile {
              childImageSharp {
                original {
                  src
                  width
                }
                fluid(maxWidth: 880) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          tradeStructuringContent {
            text
            title

            footnoteSymbol
          }

          tradeStructuringFootnotes {
            footnoteSymbol
            linkText
            modalCloseButton
            text
            modalContent {
              text
              title
            }
          }

          riskManagementTitle
          riskManagementImage {
            mediaItemUrl
            mediaItemId
            modified
            localImageFile {
              childImageSharp {
                original {
                  src
                  width
                }
                fluid(maxWidth: 880) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          riskManagementImage2 {
            mediaItemUrl
            mediaItemId
            modified
            localImageFile {
              childImageSharp {
                original {
                  src
                  width
                }
                fluid(maxWidth: 880) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          riskManagementContent {
            text
            title
          }

          leadershipSection {
            name
            title
            text
            quoteText
            fullName
            companyPosition
            imageText
            image {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            imageMobile {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            ceoPhoto {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid(maxWidth: 840) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            image2 {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid(maxWidth: 880) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          methodologySection {
            leftTextColumn
            rightTextColumn
            title
            name
            image {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid(maxWidth: 880) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            list {
              text
              title
            }
          }
          globeSection {
            name
            leftTextColumn
            rightTextColumn
            title

            acflinkAcfLink {
              externalUrl
              fieldGroupName
              linkText
              type
              openInNewWindow
              linkToPage {
                ... on WPGraphQL_Page {
                  uri
                }
              }
            }
          }
          
          banner {
            name
            title
            image {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            imageMobile {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            acfLink {
              externalUrl
              fieldGroupName
              linkText
              type
              openInNewWindow
              linkToPage {
                ... on WPGraphQL_Page {
                  uri
                }
              }
            }
          }
        }
      }
    }

  }
`