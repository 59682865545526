import React from "react"
import VisibilitySensor from "react-visibility-sensor"
import classNames from "classnames"

import { SectionTitle } from "../elements/section-title"
import { RevealImage } from "../elements/reveal-image"

import { NumMain } from "../styles/num.styles"
import { SectionTextMain } from "../styles/common.styles"

const ImgBlock = (props) => {
  return (
    <div className={classNames(`img-wrap`, `${props.image.size || 'large'}`)}>
      <RevealImage image={props.image} variation={props.variation} />
    </div>
  )
}

const NumberBlock = ({ number, className }) => {
  return (
    <div className={classNames(className, 'number')}>
      {`0${number}`}
    </div>
  )
}

export const NumSection = ({ number, title, items, image1, image2, footnotes, modalProps, alignFootNotesBelow = false }) => {
  const variation = number % 2 === 0 ? 'reverse' : 'default';
  const type = (number - 1) % 3;
  return (
    <VisibilitySensor partialVisibility={true} offset={{ top: 100, bottom: 100 }}>
      {({ isVisible }) =>
        <div className={`to-animate ${isVisible ? 'is-in-vp' : ''}`}>

          <NumMain className={`${variation} num-${type}`}>
            <div className="flex-wrap">
              <div className="main-col">
                <NumberBlock number={number} />

                <SectionTitle text={title} delay={200} />

                <SectionTextMain style={{ transitionDelay: '600ms' }}>
                  {items.map(({ title, text, footnoteSymbol }, i) => (
                    <div key={i} className={`num-item ${title ? 'has-title' : ''}`}>
                      {title && (
                        <div className="num-title">
                          {title}
                        </div>
                      )}
                      <p>
                        {String(text).trim()}{footnoteSymbol && <sup>{footnoteSymbol}</sup>}
                      </p>
                    </div>
                  ))}
                </SectionTextMain>
                {!alignFootNotesBelow? 
                    <div>
                    {(footnotes || []).map(({ footnoteSymbol, linkText, text, modalContent, modalCloseButton }) => {
                        return (
                        <div key={footnoteSymbol} className="footnote-item">
                            <span className="footnote-num">{footnoteSymbol}{'. '}</span>{text}
                            <button
                            type="button"
                            className="modal-btn"
                            onClick={() => {
                                // NOTE: opneing modal and passing in data because footnoteSymbol is not reliable
                                modalProps.openModal({
                                override: {
                                    modalData: {
                                    title: modalContent?.title,
                                    content: { introContent: [], sections: [{ title: '', text: modalContent?.text }] },
                                    closeBtn: modalCloseButton
                                    }
                                }
                                })
                            }}
                            >
                            {linkText}
                            </button>
                        </div>
                        )
                    })}
                    </div> : ""}
                </div>

              <div className="side-col">
                {image1 && <ImgBlock image={{ ...image1 }} variation={image1.size !== 'small' ? 'from-right' : 'from-left'} />}
                {image2 && <ImgBlock image={{ ...image2 }} variation={image2.size !== 'small' ? 'from-right' : 'from-left'} />}
              </div>
            </div>

            {alignFootNotesBelow? 
                <div className="below-col">
                    {(footnotes || []).map(({ footnoteSymbol, linkText, text, modalContent, modalCloseButton }) => {
                        return (
                        <div key={footnoteSymbol} className="footnote-item">
                            {/* <span className="footnote-num">{footnoteSymbol}{'. '}</span>{text} */}
                            <div>
                                <p>{footnoteSymbol}{'. '}
                                 <span className="footnote-item-title">{modalContent.title}:</span> {text}</p>
                            </div>
                           
                        </div>
                        )
                    })}
                </div>: ""
            }
           

          </NumMain>
        </div>
      }
    </VisibilitySensor>
  )
}
