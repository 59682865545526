import React from "react"

import { LgListMain } from "../styles/common.styles"

export const LgList = ({ items }) => {
  return (
    <LgListMain>
      {items.map(({ title, text }, i) => (
        <li key={i} className="lg-list-item">
          <div className={'lg-list-num'} style={{transitionDelay: `${i * 200}ms`}}>
            {i + 1}
          </div>
          <div className="lg-list-ovf">
            <div className="lg-list-title" style={{transitionDelay: `${50 + i * 200}ms`}}>
              {title}
            </div>
            <div className="lg-list-text" style={{transitionDelay: `${100 + i * 200}ms`}}>
              {text}
            </div>
          </div>
        </li>
      ))}
    </LgListMain>
  )
}
