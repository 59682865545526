import styled from "styled-components"

import { HexagonTitleMain, col, SectionTextMain, SectionTitleMain } from "./common.styles"

export const MethodologyMain = styled.div`
position: relative;
padding: 81px 0 177px;
background-color: ${props => props.theme.colors.secondary300};

${props => props.theme.max('sm', (props) => (`
  padding: 86px 0 96px;
`), props)}

.right-col {
  margin-left: auto;
  margin-right: 17%;

  ${props => props.theme.max('md', () => (`
    margin-right: 0;
  `))}
}

&:before {
  left: 48%;
  width: 53%;
  height: 126px;
}

.row {
  position: relative;
  z-index: 2;
}

.main-col {
  ${col(100)}

  ${props => props.theme.max('sm', (props) => (`
    order: 1;
  `), props)}
}

.img-col {
  ${col(46)}

  ${props => props.theme.max('sm', (props) => (`
    order: 3;
    ${col(100)}
  `), props)}
}

.side-col {
  ${col(41.6)}
  margin-left: auto;
  padding-top: 46px;

  ${props => props.theme.max('md', (props) => (`
    ${col(50)}
  `), props)}

  ${props => props.theme.max('sm', (props) => (`
    ${col(100)}
    order: 2;
    padding-top: 0;
  `), props)}
}

${SectionTitleMain} {
  margin-bottom: .43em;
  ${props => props.theme.max('sm', (props) => (`
    max-width: 270px;
    margin-bottom: 40px;
  `), props)}
}

${SectionTextMain} {
  ${col(33)}
  margin-bottom: 114px;

  ${props => props.theme.max('md', () => (`
    ${col(42)}
  `))}
  
  ${props => props.theme.max('sm', (props) => (`
    ${col(100)}
    margin-bottom: 15px;
  `), props)}
}

${HexagonTitleMain} {
  ${props => props.theme.max('sm', (props) => (`
    margin-bottom: 63px;
  `), props)}
}

.lg-list-item {
  padding-bottom: 102px;

  ${props => props.theme.max('sm', (props) => (`
    padding-bottom: 54px;
  `), props)}

  &:after {
    border-right: 1px solid rgba(255, 255, 255, .2);
  }
}

.b-col {
  align-items: center;
  ${col(100)}

  ${props => props.theme.max('sm', () => (`
    order: 2;
  `))}
}
`