import styled from "styled-components"

import { containerSideOffset, SectionTextMain, col } from "./common.styles"

export const NumMain = styled.div`
position: relative;
padding: 177px 0 40px;

${props => props.theme.max('md', () => (`
  padding: 100px 0 40px;
`))}

${props => props.theme.max('sm', (props) => (`
  padding: 70px 0 0;
`), props)}

.flex-wrap {
  align-items: flex-end;
}

${SectionTextMain} {
  ${props => props.theme.min('sm', () => (`
      max-width: 440px;
  `))}
}

&.default {
  .main-col {
    ${containerSideOffset('left', 4)}
    padding-bottom: 170px;

    ${props => props.theme.max('md', () => (`
      padding-bottom: 70px;
    `))}
    
    ${props => props.theme.max('sm', () => (`
      padding-bottom: 0;
    `))}
  }

  .side-col {
    margin-left: auto;
    ${containerSideOffset('right', 2)}
  }
  
}

&.reverse {
  padding: 50px 0 50px;

  ${SectionTextMain} {
    ${props => props.theme.min('sm', () => (`
      max-width: 420px;
    `))}
  }
  
  .main-col {
    margin-left: auto;
    ${containerSideOffset('right', 4)}
  }
  
  .side-col {
    ${containerSideOffset('left', 2)}
    padding-bottom: 170px;

    ${props => props.theme.max('md', () => (`
      padding-bottom: 70px;
    `))}
  }

  .below-col {
    ${containerSideOffset('left', 2)}
    ${containerSideOffset('right', 4)}
    padding-bottom: 70px;
    padding-top: 70px; 
    .footnote-item {
      max-width: 100%;
      margin-bottom: 2rem;
      .footnote-item-title {
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }

  .flex-wrap {
    flex-direction: row-reverse;
  }

  ${props => props.theme.max('sm', (props) => (`
    padding: 50px 0 0;
  `), props)}
}

.main-col {
  ${col(7, 'c')}

  ${props => props.theme.max('md', (props) => (`
    ${col(50)}
  `), props)}
  
  ${props => props.theme.max('sm', (props) => (`
    ${col(100)}
  `), props)}
}

&.num-0 {
  .side-col {
    ${col(9, 'c')}

    ${props => props.theme.max('md', (props) => (`
      ${col(50)}
    `), props)}

    ${props => props.theme.max('sm', (props) => (`
      ${col(100)}
    `), props)}
  }
}

&.num-1 {
  
  .side-col {
    ${col(7, 'c')}
    /* TODO: remove after uploading new images */
    padding-top: 40px;

    ${props => props.theme.max('md', (props) => (`
      ${col(50)}
    `), props)}

    ${props => props.theme.max('sm', (props) => (`
      ${col(100)}
      padding-bottom: 0;
    `), props)}
  }

  .img-wrap {
    max-width: 86%;

    ${props => props.theme.max('sm', () => (`
      max-width: 100%;
    `))}

    &.small {
      max-width: 57%;
      margin-left: auto;

      ${props => props.theme.max('sm', () => (`
        display: none;
      `))}
    }
  }
}

&.num-2 {
  padding: 72px 0 120px;

  ${props => props.theme.max('sm', () => (`
    padding: 50px 0 30px;
  `))}

  .side-col {
    ${col(8, 'c')}

    ${props => props.theme.max('md', (props) => (`
      ${col(50)}
    `), props)}

    ${props => props.theme.max('sm', (props) => (`
      ${col(100)}
    `), props)}
  }

  .img-wrap {
    max-width: 88%;

    ${props => props.theme.max('sm', () => (`
      max-width: 100%;
    `))}

    &.small {
      max-width: 38%;
      margin-left: auto;

      ${props => props.theme.max('sm', () => (`
        display: none;
      `))}
    }
  }
}


.number {
  font-family: ${props => props.theme.fonts.tertiary};
  color: ${props => props.theme.colors.secondary};
  font-size: 316px;
  ${col(100)}
  line-height: 1.1;

  ${props => props.theme.max('mac', (props) => (`
    font-size: 250px;
  `), props)}

  ${props => props.theme.max('md', () => (`
    font-size: 230px;
  `))}

  ${props => props.theme.max('xs', () => (`
    font-size: 190px;
  `))}
}

.to-animate & {
  .number {
    opacity: 0;
  }
}

.to-animate:not(.is-in-vp) & {
  .number {
    transition-delay: 0ms !important;
  }
}

.to-animate.is-in-vp & {
  .footnote-item {
    transition: ${800}ms ease 800ms;
    opacity: 1;
    transform: translateY(0);
  }

  .number {
    transition: ${800}ms ease 300ms;
    opacity: 1;
  }
}

.img-wrap {
  ${col(100)}
  margin-bottom: 34px;
}

.modal-btn {
  cursor: pointer;
  appearance: none;
  border: none;
  background: none;
  text-decoration: underline;    
  color: currentColor;
  padding: 0;

  &:hover {
    text-decoration: none;
  }
}

.footnote-item {
  position: relative;
  opacity: 0;
  line-height: 1.5;
  display: block;
  transform: translateY(100%);
  
  ${props => props.theme.min('sm', (props) => (`
    max-width: 420px;
  `), props)}
  
  &:first-child {
    .footnote-num {
      transform: translateX(5%);
    }
  }
}

.footnote-num {
  position: absolute;
  right: 101%;
  top: 0;
  width: 1em;
  text-align: center;
}
`