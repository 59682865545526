import React from "react"
import Img from "gatsby-image"
import VisibilitySensor from "react-visibility-sensor"

import { AcfLink } from "../elements/acf-link"
import { BtnInner } from "../elements/btn-inner"
import { HexagonTitle } from "../elements/hexagon-title"
import { SectionTitle } from "../elements/section-title"

import { seoClassPrefix, unoptimzeGatsbyImageSource } from "../helpers/common"

import { Container } from "../styles/common.styles"
import { BannerMain } from "../styles/banner.styles"
import { useAgreementState } from "../context/global-agreement-context"

export const BannerSection = ({
  title,
  linkData,
  background,
  modifier,
  linkClass,
  backgroundMobile,
  titleResponsive = [],
  alternativeBg = null,
  hexagonData: {
    name,
    sectionNum,
  },
}) => {
  const { popupTermsStatus } = useAgreementState();
  return (
    <VisibilitySensor partialVisibility={true} offset={{ top: 100, bottom: 100 }} delayedCall={true}>
      {({ isVisible }) =>
        <BannerMain className={`${modifier || ''} to-animate ${isVisible && 'accepted' === popupTermsStatus ? 'is-in-vp' : ''}`}>
          <div className="banner-inner">
            {backgroundMobile && <Img fluid={unoptimzeGatsbyImageSource(backgroundMobile)} style={{ position: 'absolute' }} className="mobile" />}
            {background && <Img fluid={unoptimzeGatsbyImageSource(background)} style={{ position: 'absolute' }} className={"desktop"} />}
            {alternativeBg}
            <Container>
              <div className="banner-content">
                <HexagonTitle number={sectionNum} className={'light'}>
                  {name}
                </HexagonTitle>
                <SectionTitle text={title} responsive={titleResponsive} />
                {linkData && (
                  <AcfLink linkData={linkData} className={`btn secondary ${seoClassPrefix}${linkClass || 'banner-button'}`}>
                    <BtnInner text={linkData.linkText} delay={1000} />
                  </AcfLink>
                )}
              </div>
            </Container>
          </div>
        </BannerMain>
      }
    </VisibilitySensor>
  )
}
