import styled from "styled-components"

import { SectionTitleMain, col } from "./common.styles"

export const GlobeMain = styled.div`
position: relative;
padding: 167px 0 119px;
color: ${props => props.theme.colors.white};

a {
  color: #fff;
}

${props => props.theme.max('sm', (props) => (`
  padding: 90px 0 100px;
`), props)}

&.cutout-top:before {
  left: 48%;
  width: 53%;
  height: 125px;
}

&:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 1px;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.primary100};
}

> * {
  position: relative;
  z-index: 5;
}

.title-col {
  ${col(100)}
}

.main-col {
  ${col(40)}

  ${props => props.theme.max('md', () => (`
    ${col(47)}
  `))}

  ${props => props.theme.max('sm', (props) => (`
    ${col(100)}
  `), props)}
}

.main-col-r {
  margin-left: auto;
  margin-right: 12%;

  ${props => props.theme.max('md', () => (`
    margin-right: 0;
  `))}

  ${props => props.theme.max('sm', () => (`
    margin-bottom: 80px;    
  `))}
}

.side-col {
  margin-left: auto;
  ${col(33)}

  ${props => props.theme.max('md', (props) => (`
    ${col(45)}
  `), props)}
  
  ${props => props.theme.max('sm', (props) => (`
    ${col(100)}
  `), props)}
}

${SectionTitleMain} {
  color: ${props => props.theme.colors.white};

  ${props => props.theme.max('sm', (props) => (`
    margin-bottom: .9em;
  `), props)}
}

/* Likey placeholder styles */
.globe-wrap {
  padding-top: 19px;
  max-width: ${960 + 30}px;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;

  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}

.globe-canvas {
  border: none;
  appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: 400ms ease 300ms;

  &.has-error {
    visibility: hidden !important;
  }
}

.globe-loader {
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 30px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 400ms;
}

.spinner {
  max-width: 100px;
  width: 100px;
  height: 100px;
  border: 3px solid transparent;
  border-right-color: #fff;
  border-radius: 50%;
  animation: spinner 1s linear 0s infinite; 
}

@keyframes spinner {
  100% {
    transform: rotate(360deg);
  }
}

.load-finished {
  .globe-loader {
    opacity: 0;
    pointer-events: none;
  }
}

.loading {

  .globe-canvas {
    opacity: 0;
  }
}

.globe-callout {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.globe-legend {
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 70px;

  ${props => props.theme.max('sm', () => (`
    display: block;
    max-width: 300px;
    margin: 0 auto;
  `))}
}

.legend-dot {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 1em;

  ${props => props.theme.max('sm', () => (`
    margin-bottom: 0;
    margin-right: 1em;
    width: 1em;
    max-width: 1em;
    height: 1em;
  `))}
}

.legend-item {
  position: relative;
  padding: 0 36px;

  &:last-child {
    &:after {
      content: none;
    }
  }

  ${props => props.theme.max('sm', () => (`
    display: flex;
    align-items: center;
    margin-bottom: 1em;
  `))}

  ${props => props.theme.min('sm', () => (`
    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      height: 90%;
      width: 1px;
      background: #fff;
      opacity: .3;
    }
  `))}
}

.pulse-anim {
  position: absolute;
  opacity: .4;
  width: 10%;
  transition: opacity 400ms;
  will-change: transform;

  &.hidden {
    opacity: 0;
  }
  
  &:not(.hidden) {
    .pulse-inner {
      animation: pulseAnim 25000ms ease-in 50ms infinite;
    }
  }
}

.pulse-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border-style: solid;
  border-color: currentColor;
  border-width: 0;

  &:before {
    content: '';
    width: 100%;
    display: block;
    padding-bottom: 100%;
  }
}

@keyframes pulseAnim {
  0% {
    border-width: 0;
    transform: translate(-50%, -50%) scale(0);
  }
  5% {
    border-width: 10px;
  }
  10% {
    border-width: 0;
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    transform: translate(-50%, -50%) scale(0);
    border-width: 0;
  }
}

.globe-touch-blocker {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  &:before,
  &:after {
    content: '';
    position: absolute;
    pointer-events: all;
    display: block;
  }

  &.vertical {
    &:before,
    &:after {
      height: 100%;
      width: 16%;
    }

    &:before {
      left: 0;
      top: 0;
    }
    
    &:after {
      right: 0;
      top: 0;
    }
  }
  
  &.horizontal {
    &:before,
    &:after {
      height: 16%;
      width: 100%;
    }

    &:before {
      left: 0;
      top: 0;
    }

    &:after {
      left: 0;
      bottom: 0;
    }
  }
}

`