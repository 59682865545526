import styled from "styled-components"

import { col, HexagonTitleMain, SectionTitleMain } from "./common.styles"

export const AtGlanceMain = styled.div`
position: relative;
padding-top: 168px;
padding-bottom: 174px;

${props => props.theme.max('sm', (props) => (`
  padding-top: 90px;
  padding-bottom: 78px;
`), props)}

&.about-us {
  padding-top: 98px;
  padding-bottom: 253px;

  ${props => props.theme.max('sm', (props) => (`
    padding-bottom: 78px;
  `), props)}
}

.full-bg-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  padding-top: 139px;
  top: 0;
  left: 0;

  ${props => props.theme.max('sm', (props) => (`
    .gatsby-image-wrapper {
      display: none;
    }
  `), props)}

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    left: 0;
    background-color: ${props => props.theme.colors.primary100};
  }
}

/* Corrections/offsets for certain browsers */
&.cutout-bottom {
  .full-bg-wrap {
    &:before {
      height: calc(100% - 1px);
    }
  }
}

&.cutout-bottom.cutout-top {
  .full-bg-wrap {
    &:before {
      height: calc(100% - 2px);
      top: 1px;
    }
  }
}

.gatsby-image-wrapper {
  max-height: 1200px;
  height: 100%;
}

${HexagonTitleMain}, 
${SectionTitleMain} {
  position: relative;
  z-index: 5;
}

${HexagonTitleMain} {
  .hexagon-num {
    color: ${props => props.theme.colors.secondary200};
  }

  svg {
    opacity: .2;
  }
}



${SectionTitleMain} {
  color: ${props => props.theme.colors.white};
  margin-bottom: .6em;

  ${props => props.theme.max('sm', (props) => (`
    margin-bottom: 0.6em;
  `), props)}
}

.atg-list {
  position: relative;
  justify-content: space-between;
}

.atg-item-wrap {
  ${col(27.5)}
  padding-top: 47px;
  padding-bottom: 29px;

  ${props => props.theme.max('mac', (props) => (`
    ${col(30)}
  `), props)}
  
  ${props => props.theme.max('sm', (props) => (`
    ${col(50)}
  `), props)}

  ${props => props.theme.max('sf', (props) => (`
    ${col(100)}
    padding-top: 20px;
    padding-bottom: 3px;
  `), props)}
}

.atg-item-icon {
  margin-bottom: 1.5em;
  height: 20px;

  &.macro {
    transform: translate(0, -.3em);
  }
}

.atg-item-title {
  color: ${props => props.theme.colors.secondary100};
  font-size: 20px;
  margin-bottom: .6em;
}

.atg-item-text {
  color: ${props => props.theme.colors.white};
  line-height: 1.5;
}

&.to-animate {
  .atg-item-wrap {
    transform: translate(0, 80px);
    opacity: 0;
  }
}

&.to-animate:not(.is-in-vp) {

  .atg-item-wrap {
    transition-delay: 0ms !important;
  }
}

&.to-animate.is-in-vp {
  .atg-item-wrap {
    transition: ${800}ms;
    transform: translate(0);
    opacity: 1;
  }
}
`