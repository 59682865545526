import React from "react"
import VisibilitySensor from "react-visibility-sensor"

import { DotMapImage } from "../query-components/dot-map-bg"

import { atAGlanceTitleResponsive, SvgIcon } from "../helpers/common"

import { SectionTitle } from "../elements/section-title"

import { AtGlanceMain } from "../styles/at-glance.styles"
import { Container } from "../styles/common.styles"
import { HexagonTitle } from "../elements/hexagon-title"

export const AtGlanceSection = ({ cutouts, title, items = [], hexagonData: { name, sectionNum, } }) => {

  return (
    <VisibilitySensor partialVisibility={true} offset={{ top: 100, bottom: 100 }}>
      {({ isVisible }) =>
        <AtGlanceMain className={`${cutouts} to-animate ${isVisible ? 'is-in-vp' : ''}`}>
          <div className="full-bg-wrap">
            <DotMapImage />
          </div>

          <Container>
            <HexagonTitle number={sectionNum}>
              {name}
            </HexagonTitle>

            <SectionTitle text={title} responsive={atAGlanceTitleResponsive} />

            <div className="row atg-list">
              {items.map(({ itemIcon, text, title }, i) => (
                <div key={i} className="pad-col atg-item-wrap" style={{ transitionDelay: `${600 + i * 100}ms` }}>
                  <div className="atg-item">
                    <div className={`atg-item-icon ${itemIcon}`}><SvgIcon icon={itemIcon} /></div>
                    <div className="atg-item-title">{title}</div>
                    <p className="atg-item-text">{text}</p>
                  </div>
                </div>
              ))}
            </div>
          </Container>
        </AtGlanceMain>
      }
    </VisibilitySensor>
  )
}