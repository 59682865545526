export const legendDataObj = {
  fixedIncome: {
    name: "Fixed Income",
    color: "#FFBB37",
  },
  foreignExchange: {
    name: "Foreign Exchange",
    color: "#9CEA4F",
  },
  comodities: {
    name: "Commodities",
    color: "#9472F6",
  },
  equities: {
    name: "Equities",
    color: "#E98EEB",
  },
  digitalCurrency: {
    name: "Digital Assets",
    color: "#61D9FF",
  },
  credit: {
    name: "Credit",
    color: "#FDFF87",
  },
};