import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import VisibilitySensor from "react-visibility-sensor"

import { HexagonTitle } from "../elements/hexagon-title"
import { SectionTitle } from "../elements/section-title"
import { SectionText } from "../elements/section-text"
import { BtnInner } from "../elements/btn-inner"

import { legendDataObj } from "../globekit/misc-data"

import { GlobeMain } from "../styles/globe.styles"
import { Container } from "../styles/common.styles"
import { seoClassPrefix } from "../helpers/common"

import { useGlobeloaderState, useGlobeloaderDispatch } from "../context/globe-loader-context"
import { AcfLink } from "../elements/acf-link"

const GlobeCanvas = React.lazy(() => import('./globekit-canvas'));

export const GlobeSection = ({ hexagonData: { name, sectionNum, }, title, text1, text2, linkClass, linkData }) => {
  const { maxStep, step } = useGlobeloaderState();
  const setGlobeLoader = useGlobeloaderDispatch();

  const gkAssetsData = useStaticQuery(graphql`
    query {
      pointGlobe: file(relativePath: {eq: "pointglobe.bin"}) {
        publicURL
      }
      jsonPointsGeo: file(relativePath: {eq: "globe-geo.json"}) {
        publicURL
      }
      jsonPoints: file(relativePath: {eq: "globe-points.json"}) {
        publicURL
      }
      gkweb: file(relativePath: {eq: "gkweb_bg.wasm"}) {
        publicURL
      }
      
      icosphereTexture: file(relativePath: {eq: "icosphere.png"}) {
        publicURL
      }
      couldsTexture: file(relativePath: {eq: "clouds.png"}) {
        publicURL
      }
      bgTexture: file(relativePath: {eq: "bg.png"}) {
        publicURL
      }
      atmosphereTexture: file(relativePath: {eq: "disk.png"}) {
        publicURL
      }

    }
  `)


useEffect(() => {
    console.log("gkAssetsData: ", gkAssetsData)
  }, [])

  const isSSR = typeof window === "undefined";

  const legendData = Object.values(legendDataObj);

  React.useEffect(() => {
    return () => setGlobeLoader({ type: "RESET" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <VisibilitySensor partialVisibility={true} offset={{ top: 100, bottom: 100 }}>
      {({ isVisible }) =>
        <GlobeMain className={`cutout-top to-animate ${isVisible ? 'is-in-vp' : ''}`}>
          <Container>
            <div className="row">
              <div className="pad-col title-col">
                <HexagonTitle number={sectionNum} className={'light'}>
                  {name}
                </HexagonTitle>

                <SectionTitle text={title} variation="small-title" responsive={[{ breakpoint: 1024, charLimit: 15 }]} />
              </div>

              <div className="pad-col main-col">
                <SectionText delay={800} text={text1} variation="large-text" parse={true} />
              </div>

              <div className="pad-col main-col main-col-r">
                <SectionText delay={1000} text={text2} variation="large-text" parse={true} />

                <AcfLink linkData={linkData} className={`btn secondary white-text ${seoClassPrefix}${linkClass}`}>
                  <BtnInner text={linkData?.linkText} delay={1000} />
                </AcfLink>
              </div>
            </div>
          </Container>

          <div className={`globe-wrap ${step >= maxStep ? 'load-finished' : 'loading'}`}>
            <div className="globe-touch-blocker vertical">

            </div>
            <div className="globe-touch-blocker horizontal">

            </div>

            {!isSSR && (
              <React.Suspense fallback={<div>Loading...</div>}>
                <GlobeCanvas assetsData={gkAssetsData} />
              </React.Suspense>
            )}

            <div className={`globe-loader ${step >= maxStep ? 'load-finished' : 'loading'}`}>
              <span className="spinner"></span>
              {/* <span>{`${Math.min(step, maxStep)}/${maxStep}`}</span> */}
            </div>
          </div>

          <div className="globe-legend">
            {legendData.map(({ color, name }, index) => (
              <div className="legend-item" key={index}>
                <div className="legend-dot" style={{ backgroundColor: `${color}` }}></div>
                <div>
                  {name}
                </div>
              </div>
            ))}
          </div>


        </GlobeMain>
      }
    </VisibilitySensor>
  )
}
