import React from "react"
import VisibilitySensor from "react-visibility-sensor"

import { HexagonTitle } from "../elements/hexagon-title"
import { SectionTitle } from "../elements/section-title"
import { SectionText } from "../elements/section-text"
import { RevealImage } from "../elements/reveal-image"
import { LgList } from "../elements/lg-list"

import { MethodologyMain } from "../styles/methodology.styles"
import { Container } from "../styles/common.styles"

export const MethodologySection = ({ leftTextColumn, rightTextColumn, title, list, image1, hexagonData: { name, sectionNum, } }) => {
  return (
    <MethodologyMain>
      <Container>
        <div className="row">
          <VisibilitySensor partialVisibility={true} offset={{ top: 0, bottom: 0 }}>
            {({ isVisible }) =>
              <div className={`pad-col main-col to-animate ${isVisible ? 'is-in-vp' : ''}`}>
                <HexagonTitle number={sectionNum}>
                  {name}
                </HexagonTitle>

                <SectionTitle text={title} />

                <div className="flex-wrap">
                  <SectionText text={leftTextColumn} variation="large-text" />
                  <SectionText text={rightTextColumn} variation="large-text right-col" />
                </div>
              </div>
            }
          </VisibilitySensor>
          <VisibilitySensor partialVisibility={true} offset={{ top: 0, bottom: 0 }}>
            {({ isVisible }) =>
              <div className="flex-wrap b-col">
                <div className={`pad-col img-col to-animate ${isVisible ? 'is-in-vp' : ''}`}>
                  <RevealImage image={image1} variation={'from-left'} />
                </div>
                <div className={`pad-col side-col to-animate ${isVisible ? 'is-in-vp' : ''}`}>
                  <LgList items={list} />
                </div>
              </div>
            }
          </VisibilitySensor>
        </div>
      </Container>
    </MethodologyMain>
  )
}
