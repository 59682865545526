import React from "react"
import { Link } from "gatsby"

export const AcfLink = (props) => {
  const linkData = props.linkData;

  const reducedProps = (({ linkData, ...o }) => o)(props);

  const destination = linkData?.type === 'internal' ? { to: linkData?.linkToPage?.uri } : { href: linkData?.externalUrl };

  const target = { target: linkData?.openInNewWindow ? "_blank" : "_self" };

  const newProps = { ...destination, ...target, ...reducedProps };
  
  if (!linkData?.linkToPage && !linkData?.externalUrl) {
    return (
      <React.Fragment>
      </React.Fragment>
    )
  }

  if (linkData?.type === 'internal') {
    return (
      <Link activeClassName="active" {...newProps} >
        {props.children}
      </Link >
    )
  } else {
    return (
      <a {...newProps}>
        {props.children}
      </a>
    )
  }
}